<template>
  <div class="CustomAnalysisList">
    <div style="margin-bottom: 20px">

      <v-select2 label="所属项目"
                 v-if="communityVisible"
                 :width="width"
                 v-bind="communityParams"
                 v-model="communityId"
                 @onChange="onCommunityChange"></v-select2>
      <v-select2 label="所属公司"
                 :width="width"
                 v-if="regionVisible"
                 v-bind="regionParams"
                 v-model="regionId"
                 @onChange="onRegionChange"></v-select2>
    </div>
    <iframe ref="iframe"
            v-if="iframeVisible"
            name="chart"
            class="iframe-container"
            :src="url"
            frameborder="0"></iframe>
  </div>
</template>

<script>
import { fetchChartURL } from './api'
import { communityParams, regionParams } from 'common/select2Params'
let url
export default {
  name: 'CustomAnalysisList',
  data () {
    return {
      communityParams,
      regionParams,
      width: 250,
      communityId: undefined,
      url: '',
      regionId: undefined,
      iframeVisible: true
    }
  },
  computed: {
    communityVisible () {
      return this.$route.name === 'customStatistics3'
    },
    regionVisible () {
      return this.$route.name === 'customStatistics2'
    },
    userInfo () {
      return this.$store.state.userInfo
    }
  },
  mounted () {
    this.onRouteNameChange()
  },
  methods: {
    onCommunityChange (community) {
      if (this.communityId) {
        if (url.indexOf('?') > -1) {
          this.url = `${url}&param_fvalue=${this.communityId}`
          this.iframeVisible = false
          this.$nextTick(() => { this.iframeVisible = true })
        }
      } else {
        this.url = ''
      }
    },
    onRegionChange (region) {
      if (this.regionId) {
        if (url.indexOf('?') > -1) {
          this.url = `${url}&param_fvalue=${this.regionId}`
          // 让iframe重新加载
          this.iframeVisible = false
          this.$nextTick(() => { this.iframeVisible = true })
        }
      } else {
        this.url = ''
      }
    },
    onRouteNameChange () {
      this.url = ''
      if (this.$route.name === 'customStatistics3') {
        // 项目
        this.getEnterpriseURL(3)
      } else if (this.$route.name === 'customStatistics2') {
        // 公司
        this.getEnterpriseURL(2)
      } else if (this.$route.name === 'customStatistics1') {
        // 平台
        this.getEnterpriseURL(1)
      }
    },
    async getEnterpriseURL (type) {
      let { data } = await this.$axios.get(fetchChartURL, { params: { onlyCode: 'enterprise_statistics' } })
      url = `${data.url}&param_ftype=${type}`
      if (type === 1) {
        this.url = url
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.CustomAnalysisList {
  display: flex;
  flex-direction: column;
  background: #fff;
  text-align: left;
  padding: 0 20px;
  .iframe-container {
    width: 100%;
    height: 100%;
    flex: 1;
  }
}
</style>
